import React, { useImperativeHandle, useState, useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Section, Title, Text, Span } from "../../components/Core";
import imgPhoto from "../../assets/image/png/about/cedrina.png";
import imgPhoto2 from "../../assets/image/png/about/jordan.png";
import Slider from "react-slick";
import {BrowserView, MobileView} from 'react-device-detect';

const About = ({ hero = false, bg = "dark", ...rest }, ref) => {
  const [isScrolling, setIsScrolling] = useState(false);
  const sliderRef = useRef(null);

  const settings = {
    dots: true,
    arrows: false,
    touchMove: false,
    vertical: false,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  useImperativeHandle(ref, () => ({
    dispatch(delta) {
      if (!isScrolling) {
        setIsScrolling(true);
        if (delta >= 0) {
          sliderRef.current.slickPrev();
        } else {
          sliderRef.current.slickNext();
        }
        setTimeout(() => {
          setIsScrolling(false);
        }, 2000);
      }
    },
  }));

  const cedrina = (
    <div>
              <Row className="align-items-center">
                <Col lg="6" style={{ textAlign: 'center' }}>
                  <div>
                    <img
                      src={imgPhoto}
                      alt="folio"
                      className="img-fluid"
                      style={{ height: "75%", width: "75%", margin: 'auto' }}
                    />
                  </div>
                </Col>
                <Col lg="6">
                  <div className="pl-lg-4 pt-5 pt-lg-0">
                    <Title color="light" variant="secSm">
                      Cédrina Laberge
                    </Title>
                    <Text
                      color="light"
                      className="mt-3 mt-lg-5"
                      css={`
                        font-size: 18px;
                        line-height: 1.5;
                      `}
                    >
                      Je conçois de beaux, mais surtout des bons design.
                      <br></br>
                      C’est quoi un bon design? C’est prendre en considération
                      tous les besoins, les analyser pour ensuite produire un
                      design qui rallie fonctionnalité, efficacité et bien sûr
                      l’esthétisme.
                    </Text>
                    <div className="mt-4">
                      <Text color="light">Écrivez-moi</Text>

                      <Text variant="p">
                        <a
                          href="mailto:cedrina@tangilo.ca"
                          className="font-weight-bold"
                        >
                          <Span color="light">cedrina@tangilo.ca</Span>
                        </a>
                      </Text>
                    </div>
                    {/*<Text color="light" className="mt-3 font-weight-bold">
                      Cédrina Laberge
                    </Text>
                    <Text color="light" variant="small">
                      Lead Designer à Tangilo
                    </Text>*/}
                  </div>
                </Col>
              </Row>
            </div>
  )

  const jordan = (
    <div>
              <Row className="align-items-center">
                <Col lg="6">
                  <div className="pl-lg-4 pt-5 pt-lg-0">
                    <Title color="light" variant="secSm">
                      Jordan Plamondon
                    </Title>
                    <Text
                      color="light"
                      className="mt-3 mt-lg-5"
                      css={`
                        line-height: 1.5;
                      `}
                    >
                      Développeur web basé à Montreal, Canada. Je développe des
                      applications de qualité dans diverses domaines d’affaires.
                    </Text>
                    <Text color="light" className="mt-4">
                      La notion du changement n’a pas de secret pour moi.
                    </Text>

                    <div className="mt-4">
                      <Text color="light">Écrivez-moi</Text>

                      <Text variant="p">
                        <a
                          href="mailto:jordan@tangilo.ca"
                          className="font-weight-bold"
                        >
                          <Span color="light">jordan@tangilo.ca</Span>
                        </a>
                      </Text>
                    </div>
                    {/*<Text color="light" className="mt-3 font-weight-bold">
                      Jordan Plamondon
                    </Text>
                    <Text color="light" variant="small">
                      Développeur web à Tangilo
                    </Text>*/}
                  </div>
                </Col>
                <Col lg="6" style={{ textAlign: 'center' }}>
                  <div>
                    <img
                      src={imgPhoto2}
                      alt="folio"
                      className="img-fluid"
                      style={{ height: "75%", width: "75%", margin: 'auto' }}
                    />
                  </div>
                </Col>
              </Row>
            </div>
  )

  return (
    <div ref={ref}>
      <Section hero={hero} bg={bg} {...rest} style={{ paddingTop: "25px" }}>
        <Container>
          <BrowserView>
            <Slider {...settings} ref={sliderRef}>
              {cedrina}
              {jordan}
            </Slider>
          </BrowserView>
          <MobileView>
            {cedrina}
            {jordan}
          </MobileView>
        </Container>
      </Section>
    </div>
  );
};

export default React.forwardRef(About);
