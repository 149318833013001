import React from "react";
import { Link } from "gatsby";

const Logo = ({ color = "light", height, className = "", ...rest }) => {
  return (
    <Link to="/" className={`${className}`} {...rest}>
      <svg xmlns="http://www.w3.org/2000/svg" width="110.106" height="36" viewBox="0 0 110.106 36">
        <g transform="translate(-58.322 -58.322)" color={color}>
          <line fill="currentcolor" class="a" y1="3.011" x2="0.807" transform="translate(99.178 65.799)" />
          <line fill="currentcolor" class="a" x2="0.895" y2="3.34" transform="translate(94.109 65.595)"/>
          <g transform="translate(58.322 58.322)">
            <path fill="currentcolor" class="b" d="M118.2,105.933a3.785,3.785,0,0,1-3.754,2.467,3.265,3.265,0,0,1-3.289-2.967c-.5,1.645-2.61,2.967-5.113,2.967,0,0-4.112,0-4.112-3.647,0-3.754,3-4.326,9.189-5.9V96.708c0-3.683-1.931-5.649-4.612-4.362-1.251.644-1.43,1.287-1.216,1.323a1.737,1.737,0,0,1,1.216.715,1.841,1.841,0,0,1-.429,2.574,1.863,1.863,0,0,1-2.717-2.395,6.013,6.013,0,0,1,2.789-2.61,7.34,7.34,0,0,1,3.4-.715c4.29,0,5.434,2.932,5.434,7.151v5.72s0,2.36,1.681,2.36a1.067,1.067,0,0,0,1-.751Zm-12.37-2a2.529,2.529,0,0,0,2.574,2.574,2.9,2.9,0,0,0,2.717-1.967V99.461C107.3,100.319,105.834,101.928,105.834,103.93Z" transform="translate(-89.103 -81.549)"/>
            <path fill="currentcolor" class="b" d="M177.373,108.089h-6.686v-.536c1.359-.393,1.43-1.466,1.43-3.29V96.934a3.176,3.176,0,0,0-3.289-3.5,3.773,3.773,0,0,0-3.361,3V104.3c0,1.967.215,2.9,1.465,3.253v.536h-6.65v-.536c1.359-.393,1.395-1.43,1.395-3.253v-9.01c0-1.251-.322-1.68-1.788-2.288v-.536l5.577-1.216v3.789a6.529,6.529,0,0,1,5.72-3.789c2.825,0,4.719,1.251,4.719,5.578v7.436c0,1.967.179,2.968,1.466,3.29v.536Z" transform="translate(-130.002 -81.56)"/>
            <path fill="currentcolor" class="b" d="M237.367,94.066a1.528,1.528,0,0,1-.358-1.323c.036-.286-.965-.107-1.573.715a4.965,4.965,0,0,1,1.322,3.432c0,2.9-2.538,5.435-6.829,5.435a8.222,8.222,0,0,1-3.361-.572c-1.287.358-3.29,3.683.715,3.683h4.648c2.467,0,6.686.072,6.042,5.113,0,0-.143,2.9-3.9,5.006-4.934,2.753-11.012,2.324-12.442-.5-1.287-2.5.608-5.256,2.824-6.4a2.98,2.98,0,0,1-1.716-2.86,4.556,4.556,0,0,1,3.289-4.29,5,5,0,0,1-2.86-4.613c0-2.9,2.538-5.4,6.757-5.4a7.6,7.6,0,0,1,5.113,1.609,4.843,4.843,0,0,1,3.289-2.038,2.063,2.063,0,0,1,1.823.608,1.85,1.85,0,0,1-.214,2.574A1.814,1.814,0,0,1,237.367,94.066Zm-3.576,21.022a4.116,4.116,0,0,0,2.288-3.79s-.215-1.859-3.039-1.859h-4.934a7.845,7.845,0,0,1-3.075-.465c-3.361,2.038-.715,5.041-.751,5.005C226.1,116.161,230.537,117.055,233.791,115.088Zm-3.861-23.06c-1.787,0-3.111.858-3.111,4.862s1.538,4.9,3.111,4.9c1.645,0,3.182-.894,3.182-4.9S231.467,92.028,229.93,92.028Z" transform="translate(-173.283 -81.409)"/>
            <path fill="currentcolor" class="b" d="M297.839,90.852h-6.65v-.536c1.359-.429,1.431-1.466,1.431-3.29V78.088c0-1.216-.358-1.716-1.824-2.324v-.536l5.578-1.216V87.027c0,1.967.178,2.932,1.465,3.29v.536Zm-5.828-21.881a2.122,2.122,0,0,1,2.11-2.145,2.182,2.182,0,0,1,2.181,2.145,2.152,2.152,0,0,1-2.181,2.11A2.091,2.091,0,0,1,292.011,68.971Z" transform="translate(-222.388 -64.324)"/>
            <path fill="currentcolor" class="b" d="M329.184,84.851H322.5v-.536c1.358-.393,1.43-1.466,1.43-3.29V62.183c0-1.251-.322-1.788-1.788-2.181v-.536l5.578-1.144v22.7c0,1.967.179,2.968,1.465,3.29v.536Z" transform="translate(-244.51 -58.322)"/>
            <path fill="currentcolor" class="b" d="M362.58,108.409c-5.435,0-8.366-3.9-8.366-8.581,0-4.719,2.931-8.581,8.366-8.581,5.47,0,8.4,3.862,8.4,8.581C370.982,104.512,368.05,108.409,362.58,108.409Zm0-16.625c-2.645,0-3.9,1.573-3.9,8.044s1.251,8.044,3.9,8.044c2.682,0,3.9-1.573,3.9-8.044S365.262,91.784,362.58,91.784Z" transform="translate(-267.145 -81.558)"/>
            <g transform="translate(0 0.329)">
              <path fill="currentcolor" class="b" d="M69.3,82.708a5.314,5.314,0,0,1-4.755,3.253c-2.681,0-4.326-1.144-4.326-5.47V66.109H58.611l-.289-1.128h1.895V60.048l3.79-.608v5.542h4.255l-.286,1.128H64.007V80.384c0,2.432.93,3.433,2.682,3.433a2.464,2.464,0,0,0,2.145-1.395Z" transform="translate(-58.322 -59.44)"/>
              <path fill="currentcolor" class="b" d="M96.1,78.273h1.99V79.4H95.823Z" transform="translate(-84.788 -72.731)"/>
            </g>
            <path fill="currentcolor" class="b" d="M417.92,137.26a2.121,2.121,0,0,1,2.11-2.145,2.182,2.182,0,0,1,2.181,2.145,2.152,2.152,0,0,1-2.181,2.109A2.091,2.091,0,0,1,417.92,137.26Z" transform="translate(-312.105 -112.518)"/>
          </g>
        </g>
      </svg>
    </Link>
  );
};

export default Logo;
